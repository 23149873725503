import {
    CssBaseline,
    Container,
    Stack,
    ThemeProvider
} from "@mui/material";

import theme from "./theme";
import AbilityCard from "./components/AbilityCard";
import Description from "./components/Description";
import Footer from "./components/Footer";
import TopBar from "./components/TopBar";

function App() {
    const styles = {
        abilityCard: {
            flexDirection: {xs: 'column', lg: 'row'},
            width: '100%',
            padding: '25px',
            '& > :not(style)': {
                m: 1,
                width: 380,
                height: 400
            }
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <TopBar />
            <Container sx={{paddingTop: '25px'}}>
                <Description />
                <Stack alignItems="center" justifyContent="center" gap={2}
                    sx={styles.abilityCard}>
                    <AbilityCard name="architect" text="I am constantly challenging myself. To be an expert is to be at
                        the cutting edge of modern software design. Nothing makes me happier than designing useful
                        websites for clients with all they need, with nothing they don't need, and all while saving
                        them money." />
                    <AbilityCard name="developer" text="Awesome software is a work of art. Since I started developing
                        software, it never lost its initial magic to me. I put my whole heart into developing my
                        software and will only release software when it is the best version of what it can be." middle />
                    <AbilityCard name="hero" text="Building software is a journey. It's a skillset not all have nor want
                        to have. I never want my clients feeling lost. We either succeed or fail together, and the best
                        way to succeed is to give my clients a thorough understanding of their purchase." />
                </Stack>
            </Container>
            <Footer />
        </ThemeProvider>
  );
}

export default App;
