import {AppBar, Toolbar, Typography} from "@mui/material";

export default function TopBar() {
    return (
        <AppBar position="static" elevation={0}>
            <Toolbar>
                <Typography variant="h6" sx={{flexGrow: 1, textTransform: 'uppercase'}}>
                    Sites By Munday
                </Typography>
            </Toolbar>
        </AppBar>
    );
}