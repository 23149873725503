import {Alert, Button, Typography} from "@mui/material";
import {useEffect, useState} from "react";

export default function ConsultationButton() {
    const [show, setShow] = useState(false);
    const copyEmail = () => {
        window.navigator.clipboard.writeText('scott@sitesbymunday.com');
        setShow(true);
    }

    useEffect(() => {
        if (!show) {
            return;
        }

        const timeout = setTimeout(() => {
            setShow(false);
        }, 15000);

        return () => {
            clearTimeout(timeout);
        }
    }, [show]);

    return (
        <Typography sx={{height: '125px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            {!show && (<Button onClick={copyEmail}>
                <Typography variant="h5">
                    Click me to copy my email address to your clipboard
                </Typography>
            </Button>)}
            {show && (<Alert variant="outlined" severity="success" sx={{width: '100%', height: 'fit-content'}}>
                My email address is copied to your clipboard. Send me an email for your free consultation!
            </Alert>)}
        </Typography>
    );
}
