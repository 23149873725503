import {Card, CardActionArea, CardContent, CardMedia, Divider, Typography} from "@mui/material";
import {useMemo} from "react";
import _ from "lodash";

export default function AbilityCard({name, text, middle=false}) {
    const altText = useMemo(() => _.capitalize(name), [name]);
    const title = useMemo(() => _.capitalize(name), [name]);
    const imageSrc = useMemo(() => `/static/img/${_.lowerCase(name)}-sm.png`, [name]);
    const [elevation, style] = useMemo(() => middle ?
        [6, {boxShadow: 8, height: '460px !important'}] : [2, {boxShadow: 4}], [middle]);

    return (
        <Card elevation={elevation} sx={style}>
            <CardActionArea sx={{
                height: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: 'column'
            }}>
                <CardMedia
                    component="img"
                    alt={altText}
                    height="140"
                    image={imageSrc} />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {title}
                    </Typography>
                    <Divider />
                    <Typography variant="body1" color="text.secondary" sx={{paddingTop: '20px', height: '100%'}}>
                        {text}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
