import {Divider, Stack, Typography} from "@mui/material";
import ConsultationButton from "./ConsultationButton";

export default function Description() {
    return (
        <Stack gap={2}>
            <Typography variant="h3" sx={{textTransform: 'uppercase'}}>
                I am your IT Pro
            </Typography>
            <Divider />
            <Typography variant="body1">
                After working in IT for over 20 years, I feel like I have a great understanding of what makes
                a good website just work. I have worked across every aspect of the technology from the server
                code to the cloud to databases to its overall user experience. Living in Indiana for my entire
                adult life, I am a local professional that is best qualified to fit your needs to build your
                next, great website.
            </Typography>
            <ConsultationButton />
        </Stack>
    );
}