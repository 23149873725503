import {Box, Container, Divider, Link, Stack, Typography} from "@mui/material";

export default function Footer() {
    return (
        <Box
            sx={{
                width: "100%",
                height: "auto",
                paddingTop: "1rem",
                paddingBottom: "1rem",
                marginTop: 'auto'
            }}
        >
            <Container elevation={2} maxWidth="lg">
                <Stack direction="row"
                       justifyContent="center"
                       alignItems="center"
                       divider={<Divider orientation="vertical" flexItem sx={{backgroundColor: 'text.primary'}}/>}
                       spacing={2}>
                    <Typography color="textSecondary" variant="subtitle1">
                        {`© ${new Date().getFullYear()}`}
                    </Typography>
                    <Link href="https://sitesbymunday.com" color="inherit">
                        sitesbymunday.com
                    </Link>
                </Stack>
            </Container>
        </Box>
    );
}
